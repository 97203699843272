import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import Tooltip from '@material-ui/core/Tooltip';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';

import InappMessageService from '../../services/inappMessage.service';
import SpamCheckerService from '../../services/spamChecker.service';
import ProjectService from '../../services/project.service';
import AutomatedTestService from '../../services/automatedTest.service';

import '../launch-spamtest/launch-spamtest.scss';

const availableSeedlists = [1,2,3,4];


class LaunchSpamCheckerNew extends React.Component {

	constructor(props) {
		super(props);
		this.state = {test: {}, emailsSeparated: '', automatedTestsTotal: 0, currentPage: 'instant', queryParams: {}, testPool: 1};
		this.startTest = this.startTest.bind(this);
		this.selectEmailsSeparator = this.selectEmailsSeparator.bind(this);
		this.closeSpamcheckerOnboarding = this.closeSpamcheckerOnboarding.bind(this);
		this.redirectToAutomatedTestsList = this.redirectToAutomatedTestsList.bind(this);
		this.switchPool = this.switchPool.bind(this);
	}

  copyText(value){
		navigator.clipboard.writeText(value);
		if(value.includes("mlrch")){
			toast.success('Code successfully copied 🙌');
		}else if (value.includes("@")) {
			toast.success('Addresses successfully copied 🙌');
		}else{
			toast.success(value + ' copied');
		}
	}

	exportAddresses(){
		SpamCheckerService.exportAddresses().then((response) => {
			toast.success('Export processing 🙌');
		})
		.catch((error) => {
			this.setState({error: true, loading: false});
			//toast.error('Oops, we have an error');
		});
	}

  componentWillMount() {
    this.requestNewTest();

		setInterval(
			() => this.requestNewTest(this.state.testPool),
			480000
		);

		AutomatedTestService.count().then((response) => {
			this.setState({automatedTestsTotal: response.body.total})

		})
		.catch((error) => {
			toast.error('Oops. An error occured.');
		});

		let queryParams = queryString.parse(this.props.location.search);

		this.setState({queryParams});
  }

  requestNewTest(pool = null){
		this.setState({loading: true});
    SpamCheckerService.create(pool).then((response) => {
      this.setState({test: response.body, emailsSeparated: response.body.results.map(r => r.email).join(','), loading: false});
    })
    .catch((error) => {
			if(error.response.body['error'] != '401 spamchecker credits required'){
				this.setState({error: true, loading: false});
			}
    });
  }

	switchPool(){
		// Select the next pool;
		let arrayLength = availableSeedlists.length;
		let testPool = (this.state.testPool == arrayLength) ? 1 : (this.state.testPool + 1)
		this.setState({testPool})
		this.requestNewTest(testPool);
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.lastTest){
			if(nextProps.lastTest.public_id != this.state.lastTestId){
				if(this.state.lastTestId){
					toast.success('A new spamtest is running ✌️');
					// Redirect
					this.props.history.push(`/dashboard/${this.props.match.params.project_id}/spam-test/${nextProps.lastTest.test_folder_id}/results/${nextProps.lastTest.id }`);
				}
				this.setState({lastTestId: nextProps.lastTestpublic_id, start_test: false});
			}
		}

		if(nextProps.folders){
			this.setState({folders: nextProps.folders});
		}
		if(nextProps.templateKey){
			this.setState({templateKey: nextProps.templateKey});
		}
	}

	startTest(){
		setTimeout(() => {
      this.setState({start_test: false});
    }, 120000);
		this.setState({start_test: true});
	}

	closeSpamcheckerOnboarding(){
	  ProjectService.close_onboarding({id: this.props.match.params.project_id}).then((response) => {
      this.setState({onboardingClosed: true});
    })
    .catch((error) => {
      toast.error('Oops, we have an error');
    });
	}

	selectAccount(e){
    this.setState({selectAccount: e.target.value});
	}

	selectFolder(e){
		this.setState({templateKey: e.target.value});
	}

	selectEmailsSeparator(e){
		e.preventDefault();
		let separator = e.target.value;

		if(separator == 'break'){
			separator = '\n';
		}
			if(separator == 'breakc'){
			separator = ',\n';
		}
		if(separator == 'breaks'){
			separator = ';\n';
		}

		this.setState({emailsSeparated: this.state.test.results.map(r => r.email).join(separator)});
	}

	redirectToAutomatedTestsList(){
		this.props.history.push(`/dashboard/${this.props.match.params.project_id}/spam-tests/autos/lists`);
	}

	render() {

		if(this.props.currentProject.config_number_spamchecker_credits == 0){
			return(
				<>
					<div className="card upgrade-spamchecker">
						<div className="col-md-8 text-left">
							<h2>You need to get spam test credits</h2>
							<p>Buy spam test credits now to analyze your deliverability.</p>
							<NavLink to={`/dashboard/${this.props.match.params.project_id}/settings/spamchecker`} className="btn btn-primary green-bck" activeClassName="active">
								Buy credits
							</NavLink>
						</div>
						<div className="col-md-4 text-center">
							<img src="/images/upgrade.svg" />
						</div>
						<div className="clearfix"></div>
					</div>
				</>
			)
		}
		return (
			<>
						{(!this.props.currentProject.spamchecker_onboarding_closed && !this.state.onboardingClosed) &&
							<div className="card upgrade-spamchecker">
								<div className="close">
									<i onClick={this.closeSpamcheckerOnboarding} className="close fas fa-times pull-left"></i>
								</div>
									<div className="col-md-12 text-left">
										<h2>What's the spam checker and why you should use it 🎯</h2>
										<p>MailReach’s Spam Checker helps you test the spamminess of your own emails, the way YOU send them: with your content and sending setup.</p>
										<p>The email warmer raises and maintains your sender reputation, the spam checker tests your FINAL deliverability.</p>
										<p>With an email warmer subscription, you have 20 free spam test credits per month. You can always buy credits as needed.</p>
										<p>Here's an <a href="https://spamchecker.mailreach.co/tests/e9545a5d969c76ec961" target="_blank">example of a spam check</a>.</p>
									</div>
									<div className="clearfix"></div>
								</div>
							}
				<div className="title">
					<h1 className="pull-left">New spam test</h1>

					<div className="pull-right">
						{this.props.currentProject &&
							<div className="remaining-credits">
								{this.props.currentProject.config_number_spamchecker_credits} remaining credits
							</div>
						}

						<NavLink to={`/dashboard/${this.props.match.params.project_id}/settings/spamchecker`} className="btn pull-right" activeClassName="active">
							Add credits
						</NavLink>
					</div>
				</div>
				<div className="clearfix"></div>

				<ul className="spamcheck-nav-min">
					<li className={(this.state.currentPage == 'instant') ? "selected" : ""} onClick={() => this.setState({currentPage: 'instant'})}>One-off</li>
					<li className={(this.state.currentPage == 'auto') ? "selected" : ""} onClick={() => this.setState({currentPage: 'auto'})}>Automated</li>
				</ul>
				<div className="clearfix"></div>

				{(this.state.currentPage == 'auto') &&
					<>
					{this.state.automatedTestsTotal == 0 &&
						<div className="card">
							<div className="col-md-12 launch-spamtest-tuto-new text-center">

								<div className="col-md-offset-3 col-md-6 first-automated-test">
									<img src="/images/auto_test.svg" />

									<p>Run spam tests on autopilot and get alerted via Slack or webhook when your deliverability changes</p>

									<NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/auto/create`} className="btn btn-primary green-bck" activeClassName="active">
										Create an automated test
									</NavLink>
								</div>

								<div className="clearfix"></div>
							</div>
							<div className="clearfix"></div>
						</div>
					}
					{this.state.automatedTestsTotal > 0 &&
						<div className="card">
							<div className="col-md-8 launch-spamtest-tuto-new text-center automated-test-padding-top">

								<div className="col-md-offset-1 col-md-10 first-automated-test">
									<img src="/images/auto_test.svg" />

									<p>Run spam tests on autopilot and get alerted via Slack or webhook when your deliverability changes</p>
									<NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/auto/create`} className="btn btn-primary green-bck" activeClassName="active">
										Create an automated test
									</NavLink>
									</div>
							</div>
							<div className="col-md-4 automated-test-nb text-center" onClick={this.redirectToAutomatedTestsList}>
									<h2>{this.state.automatedTestsTotal}</h2>
									<p>Automated tests <br />running</p>
									<NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/autos/lists`} className="btn btn-secondary" activeClassName="active">
										Manage
									</NavLink>
								<div className="clearfix"></div>
							</div>
							<div className="clearfix"></div>
						</div>
					}
					</>
				}
				{(this.state.currentPage == 'instant') &&
					<>
			      <div className="card request-action">
			        <div className="col-md-12">

							{(!this.state.start_test && !this.state.loading) &&
								<>

								 <div className="launch-spamtest-tuto-new cde">
								 	<h3><span className="vl">STEP 1</span> : Insert this code anywhere inside your email</h3>
									<div className="email-code" onClick={() =>  this.copyText(this.state.test.public_full_id)}>
										<div className="code">
											{this.state.test.public_full_id}
										</div>
										<div className="copy">
											<i class="far fa-copy"></i>
										</div>
									</div>

									<h3><span className="vl">STEP 2</span> : Send your email campaign to these addresses</h3>

									<div className="emails-list cde pull-left" onClick={() =>  this.copyText(this.state.emailsSeparated)}>
										<div className="list">

										<div
											dangerouslySetInnerHTML={{__html: this.state.emailsSeparated}}
											/>
										</div>
										<div className="copy">
											<i class="far fa-copy"></i>
										</div>
									</div>

									<div className="col-md-12 selector">
										<select name="delimiter" id="emails-delimiter" onChange={this.selectEmailsSeparator}>
											<option value=",">Change separator</option>
											<option value=",">Comma (,)</option>
											<option value=";">Semicolon (;)</option>
											<option value="break">Line Break</option>
											<option value="breakc">Line Break with comma</option>
											<option value="breaks">Line Break with semicolon</option>
										</select>
									  <a className="download-emails-list" href={`${process.env['REACT_APP_BACKEND_URL']}spam_tests/export.csv?seedlist=${this.state.test.seedlist}`} target="_blank">Export</a>

											<div className="seedlist pointer" onClick={this.switchPool}>
												Seed list {this.state.test.seedlist} <i class="fa-solid fa-rotate"></i>
											</div>
									</div>

									<div className="pull-left col-md-12 no-padding-left">
										<NavLink to={`/dashboard/${this.props.match.params.project_id}/spam-tests/results/${this.state.test.public_id}`} className="btn btn-primary green-bck" activeClassName="active">
											STEP 3: Get your score <i class="fas fa-arrow-right"></i>
										</NavLink>
									</div>
				 				</div>
								</>
							}
							{this.state.start_test &&
								<div className="txt loading text-center">
									<div className="loader">
										<Loader
										 type="Oval"
										 color="#b7b7b7"
										 height="60"
										 width="60"
										/>
									</div>
			            <div>
									  Waiting to receive your message, it usually takes 10-30s 👀
									</div>
								</div>
							}

							{this.state.loading &&
								<div className="txt loading text-center">
									<div className="loader">
										<Loader
										 type="Oval"
										 color="#b7b7b7"
										 height="60"
										 width="60"
										/>
									</div>
									<div>
									</div>
								</div>
							}
									        </div>
			        <div className="clearfix"></div>
						</div>
					</>
				}
			</>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LaunchSpamCheckerNew));
