import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {ToastContainer, toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import queryString from 'query-string';


import AccountService from '../../../../../services/account.service';
import AutomatedTestService from '../../../../../services/automatedTest.service';


class DashboardSpamTestAutomatedTemplate extends React.Component {
	constructor(props) {
		super(props);
    this.state = {spamLogs: [], automatedTest: {}, messageContentPreview: 'content_html'};
    //this.createAutomatedTest = this.createAutomatedTest.bind(this);
    this.onChange = this.onChange.bind(this);
		this.waitingForTemplate = this.waitingForTemplate.bind(this);
		this.reloadAutomatedTest = this.reloadAutomatedTest.bind(this);
		this.reinitTemplate = this.reinitTemplate.bind(this);
	}

	componentWillMount() {

    AutomatedTestService.get(this.props.match.params.automated_test_id).then((response) => {
      this.setState({automatedTest: response.body})
    })
    .catch((error) => {
      this.setState({error: true, signupForm: false, loading: false});
      toast.error('Oops. An error occured.');
    });
	}

	reloadAutomatedTest(){
		AutomatedTestService.get(this.props.match.params.automated_test_id).then((response) => {

			if(response.body.template_defined){
				 this.setState({automatedTest: response.body, waitingForTemplate: false})
			}else{
				setInterval(
					() => this.reloadAutomatedTest(),
					8000
				);
			}
    })
    .catch((error) => {
      this.setState({error: true, signupForm: false, loading: false});
      toast.error('Oops. An error occured.');
    });
	}

  onChange(event) {
		const field = event.target.name;
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		return this.setState({selectedAccountId: value});
	}

	waitingForTemplate(){
		this.setState({waitingForTemplate: true});
		// Todo refresh

		setInterval(
			() => this.reloadAutomatedTest(),
			8000
		);
	}

	copyText(value){
		navigator.clipboard.writeText(value);
		toast.success('Address successfully copied 🙌');
	}

	reinitTemplate(){
		let automatedTest = this.state.automatedTest;
		automatedTest.template_defined = null;

		this.setState({waitingForTemplate: false, automatedTest});
	}


	render() {
		if(this.state.loading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

		if(this.state.automatedTest.template_defined){
			return(
				<div className="app container text-center spamchecker-auto-creator">
					<div className="progress-automated-test">
						<ul>
							<NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/accounts`} className="done"></NavLink>
							<li className="selected"></li>
							<li></li>
							<li></li>
							<li></li>
						</ul>
						<div className="bar"></div>
					</div>

					<h1 className="text-left">This is your email</h1>

					<div className="card message-design">
						<div className="email-style text-left">

						<h1 className="text-left">{this.state.automatedTest.subject}</h1>
						<div className='from'>
						{this.state.automatedTest.accounts[0] &&
							<>
								<b>{this.state.automatedTest.accounts[0].first_name} {this.state.automatedTest.accounts[0].last_name}</b> {'<' + this.state.automatedTest.accounts[0].email + '>'}
							</>
						}

							<ul className='format-selector pull-right'>
								<li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_html' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_html'})}>HTML</li>
								<li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_text' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_text'})}>TEXT</li>
							</ul>
						</div>
						<div className="txt">
						<div
dangerouslySetInnerHTML={{__html: this.state.automatedTest[this.state.messageContentPreview]}}
/>
						</div>

						</div>
					</div>

					<div className="text-center automated-spamtest-btns">
						<button className="btn btn-primary grey" onClick={this.reinitTemplate}>Go back</button>

						<NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/target`} className="btn btn-primary purple">
							Confirm >
						</NavLink>
					</div>
				</div>
			)
		}

		if(this.state.waitingForTemplate){
			return(
				<div className="app container text-center">
				<div className="progress-automated-test">
					<ul>
						<li className="done"></li>
						<li className="selected"></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
					<div className="bar"></div>
				</div>

					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
					<p className="loading-padding-text">MailReach is looking for your email, it usually takes 20-60 seconds 🕛</p>

					<button className="btn btn-primary grey" onClick={() => this.setState({waitingForTemplate: false})}>Go back</button>
				</div>
			)
		}

		return(
			<>
        <div className="app container home">

          <div className="progress-automated-test">
            <ul>
              <li className="done"></li>
              <li className="selected"></li>
              <li></li>
              <li></li>
							<li></li>
            </ul>
            <div className="bar"></div>
          </div>



          <h1>Send us the email to test</h1>

					<div className="card automated-spamcheck">

					<div className="alert alert-purple-light yellow">
						<p>To get the most reliable results, send your email in the most similar conditions as when running your campaigns.</p>
						<br />
						<p><b className="little">Guidelines :</b></p>
						<br />
						<ol>
							<li>
								<span className="pull-left">If you use a solution to automate your sending, use it to send your email or forward us an email sent using your sending tool. &nbsp;</span>
								<Tooltip title='As tracking is a major cause of content-related spam, your email should be tested including tracking elements (if you use tracking in your emails)' placement="left">
									<div className="pull-left">
										<i class="fa-solid fa-circle-info"></i>
									</div>
								</Tooltip>
							</li>
							<br />
							<div className="clearfix"></div>
							<li>
								<span className="pull-left">In your email, don’t include the name of a recipient to avoid wrong personalization (sending “Hi Robert” to the inbox of Eva can trigger spam filters). &nbsp;</span>
								<Tooltip title='if you usually say Hi [First Name], then just say “Hi”.' placement="left">
									<div className="pull-left">
										<i class="fa-solid fa-circle-info"></i>
									</div>
								</Tooltip>

							</li>
						</ol>
						<div className="clearfix"></div>
						</div>

						<div class="launch-spamtest-tuto-new cde">
							<p><b>Send your email to this address, generated just for you 💌</b></p>
							<div class="email-code" onClick={() => this.copyText(this.state.automatedTest.key + '@test.mailreach.co')}>
								<div class="code">{this.state.automatedTest.key}@test.mailreach.co</div>
								<div class="copy"><i class="far fa-copy" aria-hidden="true"></i></div>
							</div>
						</div>
					</div>




					<div className="text-center automated-spamtest-btns">
						<NavLink to={`/dashboard/${this.props.currentProject.id}/spam-tests/auto/${this.state.automatedTest.id}/accounts`} className="btn btn-primary grey">
							Go back
						</NavLink>
						<button className="btn btn-primary purple" onClick={this.waitingForTemplate}>Email sent</button>
					</div>

        </div>

      </>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSpamTestAutomatedTemplate));
